<template>
    <div class="tab-pane-body">

        <!--    <div class="table-responsive">-->
        <div class="row row-xs align-items-center mg-b-20" v-for="(row, index) in attributes">
            <div class="col-md-2 mg-t-5 mg-md-t-0">
                <input :class="{'is-invalid': submitted && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter name"
                       style="height: 42px" type="text" v-model="row.name" v-validate="'required'">
                <div class="invalid-feedback" v-if="submitted && errors.has('name'+index)">{{ errors.first('name' + index) }}</div>
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
                <select :class="{'is-invalid': submitted && errors.has('type'+index)}" :name="'type'+index" aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded"
                        data-select2-id="13" data-vv-as="Attribute Type" style="height: 42px" tabindex="-1" v-model="row.fact_type" v-validate="'required'" @change="detectDefinition(row)">
                    <option v-bind:value=null>--- Please Select a type ---</option>
                    <option v-bind:key="type" v-for="(type) in data_types">{{ type }}</option>
                </select>
                <div class="invalid-feedback" v-if="submitted && errors.has('type'+index)">{{ errors.first('type' + index) }}</div>
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
                <input :name="'options'+index" :readonly="row.fact_type !== 'Text'" class="form-control rounded" v-model="row.options" placeholder="Options">
            </div>
            <div class="col-md-2 d-none d-xl-block" v-if="row.fact_type === 'Definition'">
                <select :class="{'is-invalid': submitted && errors.has('type'+index)}" :name="'definition'+index" aria-hidden="true" class="form-control select2-no-search select2-hidden-accessible rounded"
                        data-select2-id="13" data-vv-as="Definition" style="height: 42px" tabindex="-1" v-model="row.definition_ref" v-validate="'required'">
                    <option v-bind:value=null>--- Please Select Definition ---</option>
                    <option v-bind:key="definition.process_definition" v-bind:value="definition.process_definition" v-for="(definition) in definitions">{{ definition.name }}</option>
                </select>
<!--                <input :name="'definition'+index" class="form-control rounded" data-vv-as="Definition ID" placeholder="Enter Definition ID"-->
<!--                       style="height: 42px" type="text" v-model="row.definition_ref" v-validate="'required'">-->
            </div>
            <div class="col-md-2 d-none d-xl-block" v-else>
                <input :name="'group'+index" class="form-control rounded" data-vv-as="Group Name" placeholder="Enter Group"
                       style="height: 42px" type="text" v-model="row.attribute_group" v-validate="'required'" :readonly="row.fact_type === 'File' || row.fact_type === 'Location'">
            </div>
            <div class="col-md-1 mg-t-5 mg-md-t-0">
                <table>
                    <tr>
                        <td class="myClass">
                            Repeatable<br><input :name="'repeatable'+index" v-model="row.repeatable" type="checkbox" :checked="row.repeatable"
                                                 :disabled="row.fact_type === 'File' || row.fact_type === 'Location'">
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-1 mg-t-5 mg-md-t-0">
                <table>
                    <tr>
                        <td class="myClass">
                            Required<br>
                            <input v-model="row.required" :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" type="checkbox" :checked="row.required">
                            <div class="invalid-feedback" v-if="submitted && errors.has('required'+index)">{{ errors.first('required' + index) }}</div>
                        </td>
                    </tr>
                </table>
                <div class="invalid-feedback" v-if="submitted && errors.has('required'+index)">{{ errors.first('required' + index) }}</div>
            </div>
            <div class="col-md-1 mg-t-5 mg-md-t-0">
                <table>
                    <tr>
                        <td class="myClass">
                            Private?<br>
                            <input v-model="row.private" :class="{ 'is-invalid' : submitted && errors.has('private'+index) }" :name="'private'+index" type="checkbox" :checked="row.private">
                            <div class="invalid-feedback" v-if="submitted && errors.has('private'+index)">{{ errors.first('private' + index) }}</div>
                        </td>
                    </tr>
                </table>
                <div class="invalid-feedback" v-if="submitted && errors.has('private'+index)">{{ errors.first('private' + index) }}</div>
            </div>
            <div class="btn-group col-md-1 mg-md-t-0">
                <a v-if="attributes.length > 1" @click="removeRow(index)" class="btn btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                <a @click="addRow" class="btn btn-success rounded" href="javascript:void(0)" type="button" v-if="index === attributes.length-1"><i class="fa fa-plus-square"></i></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "ProcessDefinitionStepAttributes",
    props: ['attributes', 'submitted', 'removeRow', 'addRow', 'registryid', 'processdefinitionid'],
    data() {
        return {
            definitions: [],
            data_types: ['Bool', 'Text', 'Integer', 'Float', 'Date', 'File', 'Location', 'Definition'],
        };
    },
    mounted() {
        this.getDefinitions();
    },
    methods: {
        async getDefinitions(){
            console.log(this.processdefinitionid)
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions?page=0&per_page=100`);
                let self = this;
                this.definitions = data.process_definitions.filter(definition => definition.status === 'Active' && definition.process_definition != self.processdefinitionid);
            }catch (e) {

            }
        },
        detectDefinition(row){
            if(row.fact_type === 'Definition'){
                row.repeatable = true;
            }
        }
    }

}
</script>

<style scoped>
.myClass {
    text-align: center;

}
</style>