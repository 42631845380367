<template>
    <standard-page v-if="process_definition_step.name" :title="process_definition_step.name">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'provenances-home'}">Provenance</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'provenances-registries'}">Registries</router-link>
      </span>
            <span v-if="registry">
        <router-link :title="registry.name" :to="{ name: 'processdefinitions'}">Registry: {{ registry.name | truncate(10, '.....') }}</router-link>
      </span>
            <span v-if="processDefinition">
        <router-link :title="processDefinition.name" :to="{ name: 'process-definition-steps'}">Process: {{ processDefinition.name | truncate(10, '.....') }}</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'process-definition-steps'}">Steps</router-link>
      </span>
            <span>
        <router-link :to="{ name: 'view-process-definition-step', params: {stepid: stepid}}">Step: {{ process_definition_step.name | truncate(10, '.....') }}</router-link>
      </span>
            <span>Edit</span>
        </template>
        <template v-slot:content>
            <div class="az-content-label mg-b-5">Update Process Step</div>
            <p class="mg-b-20">Please keep your information always current in order to help you when you contact us.</p>
            <div class="wizard clearfix">
                <div class="steps clearfix">
                    <ul>
                        <li class="head d-inline-flex"><span :class="{active: activeTab === 0}" class="number">1</span> <span :class="{active: activeTab === 0}" class="title">Basic Details</span></li>
                        <li class="head d-inline-flex"><span :class="{active: activeTab === 1}" class="number">2</span> <span :class="{active: activeTab === 1}"
                                                                                                                              class="title">Definition Attributes</span>
                        </li>
                    </ul>
                </div>
                <div class="content clearfix">
                    <form data-vv-scope="step1">
                        <section class="body current" v-if="activeTab === 0">
                            <div class="row row-xs align-items-center mg-b-20">
                                <div class="col-md-2">
                                    <label class="form-label mg-b-0">Step Name</label>
                                </div>
                                <div class="col-md-8 mg-t-5 mg-md-t-0">
                                    <input :readonly="processDefinition.status !== 'Creating'" class="form-control" placeholder="Enter name" type="text" v-model="process_definition_step.name">
                                </div>
                            </div>

                            <div class="row row-xs align-items-center mg-b-20">
                                <div class="col-md-2">
                                    <label class="form-label mg-b-0">Description</label>
                                </div>
                                <div class="col-md-8 mg-t-5 mg-md-t-0">
                                    <textarea :disabled="processDefinition.status !== 'Creating'" class="form-control" placeholder="Enter Description" rows="3" type="text"
                                              v-model="process_definition_step.description"></textarea>
                                </div>
                            </div>
                        </section>
                    </form>
                    <form data-vv-scope="step2">
                        <section class="body current" v-if="activeTab === 1">
                            <process-definition-step-attributes :registryid="registryid" :processdefinitionid="processdefinitionid" :attributes="process_definition_step.attributes" :submitted="submitted" :addRow="addAttributeRow"
                                                                :removeRow="removeAttributeRow"></process-definition-step-attributes>
                        </section>
                    </form>
                </div>
                <div class="actions clearfix">
                    <ul>
                        <li>
                            <button :disabled="activeTab === 0" @click="prev" class="btn btn-indigo rounded">Previous</button>
                        </li>
                        <li>
                            <button @click="next" aria-hidden="false" class="btn btn-indigo rounded" v-if="activeTab === 0">Next</button>
                            <button @click="updateProcessDefinitionStep" class="btn btn-orange rounded" v-if="activeTab === 1">Submit</button>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </standard-page>
</template>

<script>
import EventBus from "@/event-bus";

import ProcessDefinitionStepAttributes from '@/components/protected/provenances/components/ProcessDefinitionStepAttributes';

export default {
    name: "EditProcessDefinitionStep",
    props: ['registryid', 'processdefinitionid', 'stepid'],
    components: {ProcessDefinitionStepAttributes},
    data() {
        return {
            submitted: false,
            registry: null,
            processDefinition: null,
            process_definition_step: {
                name: null,
                description: null,
                attributes: []
            },
            activeTab: 0,
            stepOneSubmitted: false,
            stepTwoSubmitted: false,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        addAttributeRow() {
            this.process_definition_step.attributes.push({
                name: null,
                type: "Text",
                required: true,
                attribute_group: null,
                repeatable: false,
                private: false,
                definition_ref: null
            });
        },
        removeAttributeRow(index) {
            if (this.process_definition_step.attributes[index].process_definition_step_attribute) {
                this.process_definition_step.remove_attributes.push(this.process_definition_step.attributes[index]);
            }
            this.process_definition_step.attributes.splice(index, 1);
        },

        async init() {
            await this.getRegistry();
            await this.getProcessDefinition();
            await this.getProcessDefinitionStep();
        },
        async getRegistry() {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}`);
                this.registry = data;
            } catch (e) {
                this.$toastr.e("Error retrieving Registry", "Error");
            } finally {

            }
        },
        async getProcessDefinition() {
            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}`);
                this.processDefinition = data;
            } catch (e) {
                this.$toastr.e("Error retrieving Process Definition", "Error");
            } finally {

            }
        },
        async getProcessDefinitionStep() {

            try {
                let {data} = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`);
                this.process_definition_step.name = data.name;
                this.process_definition_step.description = data.description;
                let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}/attributes?page=0&per_page=100`);
                this.process_definition_step.attributes = result.data.process_definition_step_attributes;
                this.process_definition_step.remove_attributes = [];
                this.process_definition_step.attributes.push({
                    name: null,
                    type: "Text",
                    required: true,
                    attribute_group: null,
                    repeatable: false,
                    private: false,
                });

            } catch (e) {
                console.log(e);
                this.$toastr.e("Error retrieving ProcessDefinitionStep", "Error");
            }
        },
        async updateProcessDefinitionStep() {
            if (this.process_definition_step.name) {
                this.process_definition_step.attributes = this.process_definition_step.attributes.filter(a => a.name !== null);
                this.process_definition_step.add_attributes = this.process_definition_step.attributes.filter(a => typeof a.process_definition_step_attribute === 'undefined');
                this.process_definition_step.update_attributes = this.process_definition_step.attributes.filter(a => typeof a.process_definition_step_attribute !== 'undefined');
                this.process_definition_step.add_attributes = this.process_definition_step.add_attributes.map(a=> {
                    return {
                        ...a,
                        attribute_group: a.attribute_group !== null && a.attribute_group.length >= 0 && a.attribute_group.replace(/\s/g, '').length === 0 ? null : a.attribute_group
                    }
                });
                this.process_definition_step.update_attributes = this.process_definition_step.update_attributes.map(a=> {
                    return {
                        ...a,
                        definition_ref: Number(a.definition_ref)
                    }
                });

                try {
                    EventBus.$emit('openLoader');
                    await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.processdefinitionid}/definition_steps/${this.stepid}`, this.process_definition_step);
                } catch (e) {
                    this.$toastr.e("Process Definition Step updating failed", 'Error');
                } finally {
                    this.process_definition_step.name = null;
                    EventBus.$emit('closeLoader');
                    await this.$router.push({name: 'process-definition-steps'});
                }
            } else {
                this.$toastr.e("Please provide a name first!", 'Failed');
            }
        },
        prev() {
            this.activeTab--;
        },
        next() {
            if (this.activeTab === 0) {
                this.stepOneSubmitted = true;
            } else if (this.activeTab === 1) {
                this.stepTwoSubmitted = true;
            }
            this.activeTab++;
        }
    }
}
</script>

<style scoped>


.head {
    align-items: center;
}

.head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 2.1;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (min-width: 769px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3.1;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

@media only screen and (max-width: 1366px) {
    .head .number {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        background-color: #b4bdce;
        line-height: 3;
        display: block;
        color: #ffffff;
        text-align: center;
    }
}

.head .number.active {
    background-color: #6675fa !important;
}

.head .title.active {
    color: #6675fa !important;
}
</style>